/** 
 * Author: Hemant Sharma
 * Type: Helper 
 * Objective: To provide helper functions 
 * Associated Route/Usage: Global 
*/ 

import config from 'src/config';

/**
   * Create an array which can be used as options of Dropdown
   * @items : Array of Object
   * @label : String
   * @value : String
   * @otherFields : Array of String
   * @returns : Array of Object
*/
function convertToSelectOptions(items=[], label='label', value='value', otherFields=[]) {
  if(items && items instanceof Array){
    return items.map((item)=>{
      const obj = {
        label : item[label],
        value : item[value]
      }
      if(otherFields.length > 0){
        for (let i = 0; i < otherFields.length; i++)
        {
          obj[otherFields[i]] = (item[otherFields[i]])?item[otherFields[i]]:null;
        }
      }
      return obj
    })
  } else{
    return [{
      label : "No data to select",
      value : ""
    }];
  }
}

/**
   * Used to filter an Entity fields and returns only reference fields
   * @items : Array of Object
   * @returns : Array of Object
*/
function fetchRelations(items) {
  if(items && items instanceof Array){
    return items.filter((item)=>item.actualType === 'reference')
  } else{
    return [{
      label : "No data to select",
      value : ""
    }];
  }
}

/**
   * Used to filter an Entity fields and returns non reference fields
   * @items : Array of Object
   * @returns : Array of Object
*/
function fetchOptionsWithoutRelations(items) {
  if(items && items instanceof Array){
    return items.filter((item)=>item.actualType !== 'reference')
  } else{
    return [{
      label : "No data to select",
      value : ""
    }];
  }
}

/**
   * Used to Validate a string for Password 
   * Atleast (1 lower char, 1 upper char, 1 special char and 1 number) and min length should be 8 char
   * @value : String
   * @returns : Boolean
*/
function validatePassword (value) {
    const regexp =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^()])[A-Za-z\d@$!%*?&#^()|]{8,}$/;
    return regexp.test(value);
};

/**
   * Used to Validate a string for Email 
   * Atleast @ and . should be there 
   * min 3 char should be after @ and min 2 char should be after .
   * @value : String
   * @returns : Boolean
*/  
function validateEmail (email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};
  
/**
   * Used to Validate a string for URL 
   * Atleast . should be there 
   * min 2 char should be after . and also verify the HTTP and HTTPS
   * @value : String
   * @returns : Boolean
*/  
function isValidUrl(value) {
    return /^((http(s?)?):\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
}
  
/**
   * Used to Validate a string for Name 
   * Atleast 2 char should be there 
   * There should not be any number and special chars
   * @value : String
   * @returns : Boolean
*/  
function isValidName(value) {
    return /^([a-zA-Z ]){2,30}$/i.test(value);
}
  
/**
   * Used to check if any value is exist in Array or not?
   * @searchValue : String
   * @arrayToSearch : Array
   * @returns : Boolean
*/  
function inArray (searchValue, arrayToSearch) {
    var length = arrayToSearch.length;
    for(var i = 0; i < length; i++) {
        if(arrayToSearch[i] == searchValue)
            return true;
    }
    return false;
}
  
/**
   * Used to encrypt a string
   * @text : String
   * @returns : String
*/  
function crypt (text) {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = (code) => textToChars(config.cryptKey).reduce((a, b) => a ^ b, code);
  
    return text
        .split("")
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join("");
};
  
/**
   * Used to decrypt a string
   * @text : String
   * @returns : String
*/  
function decrypt (encoded) {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const applySaltToChar = (code) => textToChars(config.cryptKey).reduce((a, b) => a ^ b, code);
    return encoded
        .match(/.{1,2}/g)
        .map((hex) => parseInt(hex, 16))
        .map(applySaltToChar)
        .map((charCode) => String.fromCharCode(charCode))
        .join("");
};
  
/**
   * Used to Validate a string for Alphanumeric 
   * Only Char and Number should there
   * @value : String
   * @returns : Boolean
*/  
function isAlphanumeric (str) {
    return str.match("^[A-Za-z0-9]+$");
} 
  
/**
   * Used to change the format of timezone offset
   * @val : number
   * @isNegative : Boolean
   * @returns : String
*/  
function changeOffsetFormat (val, isNegative=false) {
    const offsetInt = (isNegative)?(val * -1):val;
    let mins = offsetInt % 60;
    let hours = (offsetInt - mins) / 60;
    hours = hours < 10 ? '0'+hours : hours;
    mins = mins < 10 ? '0'+mins : mins;
    return (hours + ':' + mins)
}
  
/**
   * Used to get the user's current timezone offset
   * @void
   * @returns : String
*/  
function getOffset() {
    const dhks = new Date();
    const offsetDiff = dhks.getTimezoneOffset();
    if(offsetDiff < 1){
        if(offsetDiff == 0){
            return "+00:00";
        } else{
            let final = '+' + changeOffsetFormat(offsetDiff, true);
            return final;
        }    
    } else{
        let final = '-' + changeOffsetFormat(offsetDiff, false);
        return final;
    }
}

function removeIdTokenTimeLeftFromSessionStorage() {
  sessionStorage.removeItem("session_id");
  sessionStorage.removeItem("session_token");
  sessionStorage.removeItem("time_left");
}

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

function inputFieldKeyToLabel(key) {
  return key.replace('_', ' ').replace(/\w\S*/g, (word) => { return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase() });
};

export { 
    convertToSelectOptions,
    fetchRelations,
    fetchOptionsWithoutRelations,
    validatePassword, 
    validateEmail, 
    isValidUrl, 
    isValidName,
    inArray, 
    crypt, 
    decrypt,
    isAlphanumeric,
    getOffset,
    removeIdTokenTimeLeftFromSessionStorage,
    toTitleCase,
    inputFieldKeyToLabel
};