/**
 * Author: Hemant Sharma
 * Type: Configuration
 * Objective: To access global variables accross application
 * Usage: Accross application -65.0.80.82
*/

var configuration = {
    appName: "AONFLOW-ReactUI",
    apiUrl: (window.location.hostname === 'localhost') ? {
        node: 'http://localhost:4000/api/v1',
        socket: 'ws://localhost:4000/ws'
    } : {
        node: '/api/v1',
        socket: 'wss://app.aonflow.com/ws'
    },
    routes: {
        home: "/",
        afterLogin: "/dashboard",
        userOrgs: "/user/accounts",
        login: '/',
        addOrg: '/auth/new-org',
        ifInvites: '/auth/invitations',
        forgotPass: '/auth/forgot-password',
        projects: '/dashboard/projects',
        flows: '/dashboard/flows',
        settings: '/user/settings'
    },
    cryptKey: "Hemant@Aug-08",
    loaderTime: 1000   //ms
}


module.exports = configuration;
