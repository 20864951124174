/** 
 * Author: Hemant Sharma
 * Type: Index Page 
 * Objective: To bootstrap the application 
 * Associated Route/Usage: / 
 */ 

import "react-app-polyfill/ie11";  // For IE 11 support
import "react-app-polyfill/stable";
import "core-js";
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import AonStateProvider from "./context/aonStateProvider";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.min.css";

import { Provider } from "react-redux";
import store from "./redux/store";

ReactDOM.render(
  <Provider store={store}>
    <AonStateProvider>
      <App />
    </AonStateProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
