/** 
 * Author: Hemant Sharma
 * Type: Service 
 * Objective: Serve common functionality for flows 
 * Associated Route/Usage: "#/flows" 
*/ 

import config from '../config'; 
import commonService from './commonService'; 

const flowService = {
    checkExisitngFlow,
    createFlow,
    updateFlow,
    getFlowDetails,
    removeFlow,
    executeFlow,
    getFlowActivites,
    getFlowActivitesCount,
    getHistoryDetails,
    gettingSessionForEditingFlow,
    checkingSessionExpiredOrNot,
    forcefullyExpiringSession
};

export default flowService;

/**
   * Used to fetch a perticular execution details of a flow
   * @id : string
   * @projectId : string
   * @returns : Promise Object
*/
function getHistoryDetails(id, projectId) {
    return commonService.newRestCall({
        url: `${config.apiUrl.node}/flow/flow-history-detail/${id}/${projectId}`,
        method:'GET',
    });
}

/**
   * Used to check if flow name is already exist or not?
   * @flowName : string
   * @projectId : string
   * @returns : Promise Object
*/
function checkExisitngFlow(flowName,projectId){
    return commonService.newRestCall({
        url: `${config.apiUrl.node}/flow/existing-flow/${flowName}/${projectId}`,
        method:'GET',
    });
}

/**
   * Used to create a Flow within a project
   * @data : JSON Object
   * @returns : Promise Object
*/
function createFlow(data) {
    return commonService.newRestCall({
        url : `${config.apiUrl.node}/flow/create-flow`,
        method : 'POST',
        data:data
    });
}

/**
   * Used to update a Flow within a project
   * @data : JSON Object
   * @returns : Promise Object
*/
function updateFlow(data) {
    return commonService.newRestCall({
        url : `${config.apiUrl.node}/flow/update`,
        method : 'PUT',
        data:data
    });
}

/**
   * Used to remove a Flow within a project
   * @data : JSON Object
   * @returns : Promise Object
*/
function removeFlow(data) {
    return commonService.newRestCall({
        url : `${config.apiUrl.node}/flow/delete-flow`,
        method : 'DELETE',
        data:data
    });
}

/**
   * Used to execute a Flow within a project
   * @data : JSON Object
   * @returns : Promise Object
*/
function executeFlow(data) {
    return commonService.newRestCall({
        url : `${config.apiUrl.node}/flow/execute-flow`,
        method : 'POST',
        data:data
    });
}

/**
   * Used to fetch a Flow details within a project
   * @pid : String
   * @fid : String
   * @returns : Promise Object
*/
function getFlowDetails(pid, fid) {
    return commonService.newRestCall({
        url : `${config.apiUrl.node}/flow/get-flow/${pid}/${fid}`,
        method : 'GET'
    });
}

/**
   * Used to fetch all executions of a Flow
   * @pid : String
   * @fid : String
   * @status : String
   * @page : number
   * @returns : Promise Object
*/
function getFlowActivites(pid, fid, status, page) {
    return commonService.newRestCall({
        url : `${config.apiUrl.node}/flow/get-flow-history/${pid}/${fid}/${status}/${page}`,
        method : 'GET'
    });
}

/**
   * Used to fetch count of total execution of a flow
   * @pid : String
   * @fid : String
   * @returns : Promise Object
*/
function getFlowActivitesCount(pid, fid) {
    return commonService.newRestCall({
        url : `${config.apiUrl.node}/flow/get-history-count/${pid}/${fid}`,
        method : 'GET'
    });
}

/**
    * Used to get session for editing a flow
    * @data : JSON Object
    * @returns : Promise Object
 */
function gettingSessionForEditingFlow(data) {
    return commonService.newRestCall({
        url : `${config.apiUrl.node}/flow-edit-session/enter`,
        method : 'POST',
        data : data
    })
}

/**
    * Used to check whether the session is expired or not (in every 2 minute while flow editing)
    * @data : JSON Object
    * @returns : Promise Object
 */
function checkingSessionExpiredOrNot(data) {
    return commonService.newRestCall({
        url : `${config.apiUrl.node}/flow-edit-session/update-activity-status`,
        method : 'PUT',
        data : data
    })
}

/**
    * Used for forcefully expiring the session once the user completes the flow editing
    * @data : JSON Object
    * @returns : Promise Object 
 */
function forcefullyExpiringSession(data) {
    return commonService.newRestCall({
        url : `${config.apiUrl.node}/flow-edit-session/exit`,
        method : 'PUT',
        data : data
    })
}