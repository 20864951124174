/** 
 * Author: Hemant Sharma
 * Type: Config 
 * Objective: To provide error messages throughout app 
 * Associated Route/Usage: Global 
*/ 

const messages = {
  common:{
      unavailable: "Service unavailbe, try again",
      clear: "Sure you want to clear the form ?",
      reset: "Sure you want to reset the form ?",
      delete: "Sure you want to delete ?",
      fetchError: "Error fetching data",
      tokenExpired: "Refresh token expired, re-authorize the app",
      badRequest: "Bad Request, try again",
      down: "Sorry! Server is currently down due to maintenance",
      userNotFound: "This user is not registered with AonFlow"
  },
  validations:{
      required: "This is required",
      inputLength: "Input should be minimum 4 chars",
      email: "Please provide valid email",
      validName: "Please provide valid Name",
      validString: "Please provide valid String",
      validNumber: "Please provide valid Number",
      validDate: "Please provide valid Date",
      validBoolean: "Please provide valid Boolean",
      validURL: "Please provide valid URL",
      password: "Password must contain a lowercase, uppercase, number and one special char",
      passwordLength: "Password should be minimum 8 chars",
      passwordMatch: "Password and Confirm password should match",
      invalidLogin: "Password or Email entered are incorrect",
      invalidPassword: "Password entered are incorrect",
      acceptTC: "Please accept terms & Conditions",
      pastDate: "Date cannot be older than today",
      pastTime: "Time cannot be older than current",
      alreadyExists: "Already exist",
      oldAndNewPasswordMatch: "New Password can't be same as old password",
      validAddress: "Please enter a valid address",
      validPincode: "Please enter a valid pincode",
      validUserName: "Please enter a valid name",
      validPhoneNumber: "Please enter a valid Phone number"
  },
  confirm:{
    cancelInvite: "Sure you want to cancel invitation ?",
    updateStatus: "Sure you want to update status ?",
  },
  signup:{
    success: "Signup successful",
    failure: "Signup failed, try again!",
    emailExists: "Email already exists",
    // Adding two more messages as per the requirement
    validation: "Bad Request",
    errorMessage: "Something went wrong"
  },
  oauth2:{
    test:{
      success: "Connection successfull"
    }
  },
  organization:{
    success: "Organization created successfully",
    failure: "Organization creation failed, try again!",
    orgName: "Organization with this name already exist",
    roleChangeRequestSuccess: "Your request raised successfully",
    cancelRoleChangeRequestSuccess: "Your role change request has been canceled successfully",
    leaveOrgSuccess: "You has been leaved the organization successfully",
    editOrgSuccess: "Organization has been updated successfully",
    switchOrgSuccess: "Organization has been switched successfully",
  },
  activation:{
    success: "Activation successful",
    failure: "Activation failed, try again!"
  },
  invitation:{
    success: "Invitation sent successfully",
    failure: "Unable to send invitation, try again!"
  },
  project:{
    success: "Project added successfully",
    failure: "Unable to save, try again!",
    alreadyExists: "This project name already exists. Please use a different name"
  },
  flow:{
    unknown: "An exception has occured from Third party application.",
    step1:{
      updateSuccess: "Flow step1 updated successfully",
      success: "Flow Step1 saved successfully",
      failure: "Unable to save, try again!"
    },
    step2:{
      updateSuccess: "Source & Destination updated successfully",
      success: "Source & Destination saved successfully",
      failure: "Unable to save, try again!"
    },
    step3:{
      updateSuccess: "Flow updated successfully",
      success: "Flow saved successfully",
      failure: "Unable to save, try again!"
    }
  },
  flowactiv:{
    failure: "This cannot be activated. Create a connection first with Source and Destination Apps set!",
    dropdownFailure: "Cannot Create Dropdown for fields until both source and destination apps have updated tokens",
    dropdownFailureFieldsLoading: "Fields still Loading...",
    dropdownFail: "Cannot Create Dropdown for fields - Error in Source or Dest response",
    destAppUnavailable: "Cannot Activate as Destination app is not available",
    srcAppUnavailable: "Cannot Activate as Source app is not available",
    bothSrcDestAppUnavailable: "Cannot Activate as Both Source and Destination apps are not available",
    cannotActivate: "Cannot Activate Flow due to error in Response",
    mandatoryFields: "Fields (starting with *) on the target application, please map them to activate the flow",
  },
  user:{
    success: "User added successfully",
    remove: "User removed successfully",
    roleChange: "User role has been changed successfully",
    failure: "Unable to save, try again!"
  },
  save:{
    success: "Saved successfully",
    failure: "Unable to save, try again!"
  },
  update:{
    success: "Updated successfully",
    failure: "Unable to update, try again!"
  },
  delete:{
    success: "Deleted successfully",
    failure: "Unable to delete, try again!"
  },
  cancel:{
    success: "Cancelled successfully",
  },
  upload:{
    success: "File(s) uploaded successfully",
    failure: "Unable to upload, try again!"
  },
  forgotpassword:{
    success: "Password reset link has been successfully sent to the mailid",
    validation: "Bad request",
    errorMessage: "Something went wrong"
  },
  changePassword:{
    success: "Password changed successfully"
  },
  sendVerificationLink:{
    success: "Verification Link has been successfully sent to your registered mailid",
    validation: "Bad request",
    errorMessage: "Something went wrong"
  }

};

export default messages;