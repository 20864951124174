import { experimental_extendTheme as extendTheme } from "@mui/material/styles";
const theme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        mode: "light",
        primary: {
          main: "#264fda",
          light: "#5172E1",
          dark: "#1A3798",
          contrastText: "#ffffff",
        },
        secondary: {
          main: "#264fda",
          light: "#5172E1",
          dark: "#1A3798",
          contrastText: "#ffffff",
        },
        background: {
          default: "#f5f5f5",
          paper: "#ffffff",
        },
        error: {
          main: "#cf272d",
          light: "#D85257  ",
          dark: "#901B1F",
          contrastText: "#ffffff",
        },
        warning: {
          main: "#FFA451",
          light: "#FFB673",
          dark: "#B27238",
          contrastText: "#181818",
        },
        info: {
          main: "#3290A6",
          light: "#5BA6B7",
          dark: "#236474",
          contrastText: "#ffffff",
        },
        success: {
          main: "#3D9574",
          light: "#63AA8F",
          dark: "#2A6851",
          contrastText: "#ffffff",
        },
        whitebg: {
          main: "#ffffff",
          contrastText: "#212121"
        },
        greyText: {
          main: "#ffffff",
          contrastText: "#757575",
        },
        customlink: {
          main: "#264fda",
          contrastText: "#ffffff"
        },
        dataGridColor: {
          main: "rgba(38,79,218,0.12)",
        },
        divider: "rgba(0,0,0,0.1)",
        dbBrown: "#AB7D38",
        dbLightBlue: "#F6FBFF",
        dbLightGreen: "#DDF3E1",
        dbLightSkyBlue: "#C9EAF2",
        dbLightBrown: "#F0E6D6",
        dbVeryLightBlue: "#F6F8FB",
        dbVeryLightGreen: "#F1FCF3",
        dbVeryLightSkyBlue: "#EAF7FA",
        dbVeryLightBrown: "#FBF9F6",
        veryLightBlue: "#F7F9FF",
        veryLightGrey: "#FAFAFA",
        loginWhite: "#ffffff",
      },
    },
    dark: {
      palette: {
        mode: "dark",
        primary: {
          main: "#3c61de",
          light: "#6380E4",
          dark: "#2A439B",
        },
        secondary: {
          main: "#3c61de",
          light: "#6380E4",
          dark: "#2A439B",
        },
        background: {
          default: "#040816",
          paper: "#08102c",
        },
        error: {
          main: "#cf272d",
          light: "#D85257  ",
          dark: "#901B1F",
          contrastText: "#ffffff",
        },
        warning: {
          main: "#FFA451",
          light: "#FFB673",
          dark: "#B27238",
          contrastText: "#181818",
        },
        info: {
          main: "#3290A6",
          light: "#5BA6B7",
          dark: "#236474",
          contrastText: "#ffffff",
        },
        success: {
          main: "#3D9574",
          light: "#63AA8F",
          dark: "#2A6851",
          contrastText: "#ffffff",
        },
        whitebg: {
          main: "#08102c",
          contrastText: "#ffffff"
        },
        greyText: {
          main: "#08102c",
          contrastText: "#BDBDBD",
        },
        customlink: {
          main: "#ffffff",
          contrastText: "#264fda"
        },
        dataGridColor: {
          main: "rgba(38,79,218,0.20)",
        },
        divider: "rgba(255,255,255,0.1)",
        dbBrown: "#AB7D38",
        dbLightBlue: "#F6FBFF",
        dbLightGreen: "#DDF3E1",
        dbLightSkyBlue: "#C9EAF2",
        dbLightBrown: "#F0E6D6",
        dbVeryLightBlue: "#F6F8FB",
        dbVeryLightGreen: "#F1FCF3",
        dbVeryLightSkyBlue: "#EAF7FA",
        dbVeryLightBrown: "#FBF9F6",
        veryLightBlue: "#F7F9FF",
        veryLightGrey: "#FAFAFA",
        loginWhite: "#ffffff",
      },

    },
  },
  spacingValues: {
    value0: 0,
    value1: 1,
    value2: 2,
    value3: 3,
    value4: 4,
    value5: 5,
    value6: 6,
    value40Per: "40%",
    value50Per: "50%",
    valueNeg50Per: "-50%",
    valueNeg60Per: "-60%",
    valueMin520height: "600px",
    valueLoginWidth: "1164px",
    value80px: "80px",
    value152px: "152px",
    value100Per: "100%",
    value100vh: "100vh",
  },

  text: {
    center: "center",
    left: "left",
    right: "right",
  },

  gridColumns: {
    gridColumn1: 1,
    gridColumn2: 2,
    gridColumn3: 3,
    gridColumn4: 4,
    gridColumn5: 5,
    gridColumn6: 6,
    gridColumn7: 7,
    gridColumn8: 8,
    gridColumn9: 9,
    gridColumn10: 10,
    gridColumn11: 11,
    gridColumn12: 12,
  },

  space: {
    space0: 0,
    space2: 2,
    space4: 4,
    space8: 8,
    space16: 16,
    space24: 24,
    space32: 32,
    space64: 64,
  },

  display: {
    flex: "flex",
    inline: "inline",
    block: "block",
    inlineBlock: "inline-block",
  },

  position: {
    absolute: "absolute",
    relative: "relative",
  },

  cursor: {
    pointer: "pointer",
  },

  textTransform: {
    capitalize: "capitalize",
    lowercase: "lowercase",
    uppercase: "uppercase",
  },

  font: {
    size: {
      displaylarge: 72,
      display: 36,
      h1: 32,
      h2: 28,
      h3: 24,
      h4: 18,
      h5: 16,
      h6: 14,
      title: 18,
      label: 12,
      bodyLarge: 16,
      bodyMedium: 14,
      bodySmall: 12,
    },
    weight: {
      bold: '700',
      semiBold: '600',
      medium: '500',
      normal: '400',
      light: '300',
      extraLight: '200',
    },
  },
  border: {
    radius: "12px",
    borderSize: {
      b1: 1,
      b2: 2,
      b3: 3,
    },
  },
  tooltip: {
    placeAtBottom: "bottom",
    placeAtTop: "top"
  },
  typography: {
    fontFamily: "Poppins",
    errorFont: {
      fontSize: "100px",
    },
    h1: {
      fontSize: 32,
    },
    h2: {
      fontSize: 28,
    },
    h3: {
      fontSize: 24,
    },
    h4: {
      fontSize: 18,
    },
    h5: {
      fontSize: 16,
    },
    h6: {
      fontSize: 14,
    },
    body1: {
      fontSize: 14,
    },
    body2: {
      fontSize: 12
    }
  },
  spacing: 8,
  shape: {
    borderRadius: 4,
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        row: ({ theme }) => ({
          "&.Mui-selected": {
            backgroundColor: theme.vars.palette.dataGridColor.main,
          },
        }),
        cell: {
          "&.MuiDataGrid-cell:focus-within, ": {
            outline: "none !important",
          },
          caretColor: "transparent !important",
          msOverflowY: "hidden",
        },
        column: ({ theme }) => ({
          "&.MuiDataGrid-columnHeader:focus, ": {
            outline: "solid #3c61de 0px",
          },
          "&.MuiDataGrid-columnHeaderRow": {
            backgroundColor: theme.vars.palette.veryLightGrey
          },
          caretColor: "transparent",
          msOverflowY: "hidden",
        }),
      },
    },
  },
});

export default theme;
