import { combineReducers } from "redux";

const projectReducer = (state={}, action) => {
    if(action.type === 'setProject'){
        return action.payload;
    }
    return state;
}
const projectCountReducer = (state=0, action) => {
    if(action.type === 'setProjectCount'){
        return state+action.payload;
    }
    return state;
}

const reducers = combineReducers({
    project : projectReducer,
    projectCount : projectCountReducer
});

export default reducers;