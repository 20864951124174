import React, { useState, useEffect} from "react";
import AonContext from "./aonContext";
import Timezone from "../utils/data/timezones.json";

const AonStateProvider = ({ children }) => {

  const [projectDetails, setProjectDetails] = useState({
    privileges:[],
    project_id:'',
    project_name:"",
    forceRenderUsers:0
  });
  const [hitNotiCount, setHitNotiCount] = useState(false);

  const [rightSliderDetails, setRightSliderDetails] = useState({
    isCloseOnOverlay : true,
    open : 0,
    type : "switch_org",
    isConnectionCreatedFromConnectionTab : false
  });

  const [connectionCreationDetailsFromConnectionTab, setConnectionCreationDetailsFromConnectionTab] = useState({
    selectedApplicationData : {} 
  })

  const [flowDetails, setFlowDetails] = useState({
    flowState : 'new',
    id : '',
    project_id:'',
    projectName:"",
    name : "",
    description : "",
    type : "ondemand",
    schedulerState : "none",
    schedulerData : {},
    startDate: new Date(),
    startingAt: new Date(),
    endDate: new Date(),
    runForever: false,
    timeZone: {
      data : Timezone[0]
    },
    offset : '+03:30',
    flowIntervalHour: "",
    flowIntervalMin: "",
    interval: 0,
    intervalDuration: 0,
    selectedData : { type: '', data : {} },
    sourceApp: {},
    destinationApp : {},

    src_connection_id : '',
    dest_connection_id : '',
    srcApp : {},
    destApp : {},
    action : {
      id: null,
      label: '',
      value: ''
   },
    trigger : {
      id: null,
      label: '',
      value: ''
   },
    action_id : '',
    action_event : "create",
    trigger_id : '',
    trigger_event : "create",

    selectedFields : {source:[], destination:[], datatype: []},
    selectedOtherFields : {source:[], destination:[], datatype: []},

    finalData : {},
  });

  const [showSideBar, setShowSideBar] = useState(false);

  const [popupDetails, setPopupDetails] = useState({
    open : 1,
    type : "",
    data : null,
  })

  const [forceRender, setForceRender] = useState(0);

  const [showMainLoader, setShowMainLoader] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState({});
  const [jwtToken, setJWTToken] = useState("");

  const [userInvites, setUserInvites] = useState([]);

  const [openSideBar, setOpenSideBar] = useState(false);

  return (
    <AonContext.Provider
      value={{
        projectDetails, setProjectDetails,
        hitNotiCount, setHitNotiCount,
        showSideBar,setShowSideBar,
        popupDetails, setPopupDetails,
        forceRender, setForceRender,
        rightSliderDetails, setRightSliderDetails,
        flowDetails, setFlowDetails,
        showMainLoader, setShowMainLoader,
        loggedInUser, setLoggedInUser,
        jwtToken,
        setJWTToken,
        userInvites,
        setUserInvites,
        connectionCreationDetailsFromConnectionTab, setConnectionCreationDetailsFromConnectionTab,
        openSideBar, setOpenSideBar,
      }}
    >
      {children}
    </AonContext.Provider>
  );
};


export default AonStateProvider;
